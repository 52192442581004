#contact{
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#contact h2{
    color:#FBFCEA;
    font-size: 64px;
}

#contact p{
    color: #F47FDF;
    font-size: 22px;
    width: 800px;
    max-width: 95%;
    text-align: center;
    margin: 1rem 0;
}

#contact-form{
    width: 1000px;
    max-width: 95%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
}

.form-input{
    height: 60px;
    width: 100%;
    background-color: unset;
    border: 2px solid #FBFCEA;
    margin-bottom: 20px;
    padding-left: 20px;
    font-size: 16px;
    color: #FBFCEA;
}

.form-input::placeholder{
    color:#FBFCEA;
    opacity: 0.5;
}

#contact-left, #contact-right{
    width: 48%;
}

#contact-btn{
    height: 44px;
    background-color: #F47FDF;
    color: #FBFCEA;
    border-radius: 22px;
    width: 150px;
    border: unset;
    font-size: 18px;
}

#contact-checkbox{
    background-color: unset;
}

@media only screen and (max-width: 600px) {
    #contact-form{
        flex-direction: column;
        max-width: 100%;
    }

    #contact h2{
        font-size: 48px;
        text-align: center;
    }
    #contact{
        padding: 2rem 1rem;

    }

    #contact-left, #contact-right{
        width: 100%;
        text-align: center;
    }

    #contact p{
        font-size: 20px;
        max-width: 100%;

    }
  }