#terms{
    width: 1400px;
    max-width: 100vw;
    padding: 2rem;
    color: #FBFCEA;
    margin: 0 auto;
}

#terms h3{
    text-align: center;
    font-size: 48px;
}

#terms * {
    margin-bottom: 20px;
    line-height: 30px;
}


