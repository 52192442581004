#benefits {
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#benefits h2 {
  color: #FBFCEA;
  font-size: 64px;
  text-align: center;
}

#benefit-overview {
  color: #F47FDF;
  font-size: 22px;
  width: 800px;
  max-width: 95%;
  text-align: center;
  margin: 1rem 0;
}

/* Desktop Slider Container */
#benefits-slider-container {
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin: 50px 0 30px 0;
  touch-action: pan-x;
}

/* Desktop Slider */
#benefits-slider {
  display: flex;
  width: 300vw; /* Each benefit takes 1/3 of the screen width */
  transition: transform 0.5s ease;
}

.benefit-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 33.33vw;
  flex-shrink: 0;
  padding: 20px;
}

.benefit-container h3 {
  color: #FBFCEA;
  font-size: 48px;
  max-width: 260px;
  line-height: 40px;
}

.benefit-container p {
  color: #F47FDF;
  font-size: 20px;
  max-width: 260px;
  margin-top: 10px;
}

.benefit-icon {
  width: 55px;
  height: 55px;
  margin-right: 20px;
  object-fit: contain;
}

/* Step Selector for Desktop */
#steps {
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
}

.step-selector {
  width: 70px;
  height: 9px;
  background-color: #FBFCEA;
  opacity: 0.5;
  margin: 0 10px;
  cursor: pointer;
}

.step-selector-active {
  width: 70px;
  height: 9px;
  margin: 0 10px;
  background-color: #FBFCEA;
  opacity: 1;
  cursor: pointer;
}

/* Mobile View */
@media only screen and (max-width: 750px) {
  #benefits h2 {
    color: #FBFCEA;
    font-size: 48px;
    text-align: center;
    max-width: 95%;

  }

  #benefit-overview {
    font-size: 20px;
    max-width: 95%;
  }

  /* Scroll snapping for mobile */
  #benefits-slider-container {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  #benefits-slider {
    display: flex;
    width: 100%;
  }

  .benefit-container {
    width: 100vw; /* Full screen width on mobile */
    scroll-snap-align: center;  /* Change here */
    flex-shrink: 0;
    padding: 20px;
  }

  /* Hide step selectors on mobile */
  #steps {
    display: none;
  }

  /* Mobile step indicators */
  #mobile-step-indicator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .mobile-step {
    width: 10px;
    height: 10px;
    background-color: #FBFCEA;
    border-radius: 50%;
    opacity: 0.5;
    margin: 0 5px;
  }

  .mobile-step-active {
    opacity: 1;
  }
}