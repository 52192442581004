footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 4rem 2rem;
    background: linear-gradient(130deg, rgba(27,27,27,1) 0%, rgba(37,37,37,1) 100%);
    width: 100vw;
}

footer h3{
    color: #F47FDF;
    font-size: 34px;
    margin-top: 30px;
}

.social-icon{
    width: 50px;
    height: 50px;
    margin: 0 5px;
}

#socials{
    display: flex;
    justify-content: space-between;
    width: 300px;
    max-width: 80vw;
}

.footer-link{
    color: #F47FDF;
    display: block;
    margin: 10px;
    text-align: right;
    font-size: 18px;
}

@media only screen and (max-width: 650px) {
    footer{
        flex-direction: column;
        padding: 2rem 1rem;
    }
    .footer-link{
        text-align: center;
    }
    footer h3{
        margin-top: 20px;
        text-align: center;
    }

    
}