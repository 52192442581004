header{
    position: absolute;
    top: 0;
    left: calc((100vw - 1700px)/2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
}

#header-logo{
    height: 50px;
    width: 50px;
}

#header-logo-name{
    height: 35px;
    object-fit: contain;
}
  
  .icon-1, .icon-2, .icon-3 {
    width: 100%;
    height: 3px;
    background-color: #F47FDF;
    transition: all 400ms cubic-bezier(.84, .06, .52, 1.8);
    border-radius: 1.5px;
  }
  
  .icon-1 {
    transform: translateY(-8px);
  }
  
  .icon-3 {
    transform: translateY(8px);
  }
  
  .hamburger-icon {
    height: 50px;
    width: 35px;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    
  }
  
  .hamburger-icon:hover {
    transform: scale(1.1);
  }
  
  /* Animation for menu toggle */
  .icon-1.a {
    transform: rotate(45deg) translateY(4px);

  }
  .icon-3.b {
    transform: rotate(-45deg) translateY(-4px);

  }
  .icon-2.c {
    opacity: 0;
  }
  
  nav {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #FBFCEA;
    height: 100vh;
    width: 0%;
    z-index: 999;
    opacity: 0;
    transition: all 600ms cubic-bezier(.62, .04, .3, 1.56);
  }
  
  nav ul {
    margin: 20vh 1rem;

  }
  
  nav ul li {
    list-style: none;
    font-size: 48px;
    color: #252525;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    font-family: "railroad-gothic-atf", sans-serif;
    font-weight: 400;
    text-align: center;
    opacity: 1;
    transition: 0.2s;

  }

  nav ul li:hover{
    transition: 0.2s;
    cursor: pointer;
    opacity: 0.5;
  }
  
  nav.show {
    width: 600px;
    max-width: 100vw;
    opacity: 1;
  }
  

@media only screen and (max-width: 1700px) {
    header{
        left: 0;

    }
  }

@media only screen and (max-width: 650px) {
    header{
        padding: 1rem;
    }

    #header-logo-name{
      height: 25px;
  }
  #header-logo{
    height: 40px;
    width: 40px;
  }

  .hamburger-icon {
    width: 25px;
    
  }
}