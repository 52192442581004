* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'GillSans', sans-serif;
}
html,body{
  background: linear-gradient(to right, rgba(27,27,27,1) 0%, rgba(37,37,37,1) 100%);
}

h2, h3{
  font-family: "railroad-gothic-atf", sans-serif;
  font-weight: 400;
}

section {
  min-height: 80vh;
  width: 100vw;
}

#section1{
  position: relative;
  width: 100vw;
  height: 75vh
}

.video-btn{
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.1rem;
  margin: 1rem;
  background-color: unset;
  height: 45px;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
}

.video-btn:hover{
  cursor: pointer;
}

#btn-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#listen-btn{
  width: 350px;
  max-width: 45vw;
}

#colour-overlay{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background: rgba(18,18,18, 0);
  background: linear-gradient(180deg, rgba(18,18,18,0) 30%, rgba(18,18,18,1) 100%);

}

#video-wording{
  color: white;
  font-size: 25px;
  font-weight: 200;
  margin: 1rem;
  text-align: center;
}

#logo{
  margin: 2rem;
  width: 300px;
  max-width: 60vw;
}

video{
  Object-fit: cover;

}

#mobile-nav{
  display: none;
  
}

#right-arrow{
  transform: scaleX(-1);
  margin-left: 5px;
}

.fax{
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
}

.mg-btn{
    color: #FBFCEA;
    border: 2px solid #FBFCEA;
    height: 48px;
    width: 150px;
    border-radius: 24px;
    background-color: unset;
    margin: 2rem 0;
    font-weight: 600;
    font-size: 18px;
}

button:hover{
  cursor: pointer;
}

.hover{
  cursor: pointer;
}

a{
  text-decoration: none;
}

@font-face {
  font-family: GillSans;
  src: url(./Assets/GillSans.ttc);
  font-display: fallback;
}

@media only screen and (max-width: 660px) {
    
  #mobile-nav{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    text-decoration: none;
    color: white;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 999;
  }

}