#hero{
    height: 100vh;
    min-height: 600px;
    width: 100vw;
    color: #FBFCEA;
    background: rgb(27,27,27);
    background: linear-gradient(130deg, rgba(27,27,27,1) 0%, rgba(37,37,37,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 80px;
}

#hero-btn{
    color: #FBFCEA;
    border: 2px solid #FBFCEA;
    height: 48px;
    width: 150px;
    border-radius: 24px;
    background-color: unset;
    margin: 2rem 0;
    font-weight: 600;
    font-size: 18px;
}

#hero h2{
    text-transform: uppercase;
    font-size: 80px;
    width: 800px;
    max-width: 80vw;

}

@media only screen and (max-width: 750px) {
    #hero h2{
        font-size: 14vw;
    }

    #hero{
        
        padding-top: 30px;
    }
}