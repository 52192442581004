#side-tab{
    background-color: #F47FDF;
    position: fixed;
    width: 300px;
    height: 60px;
    top: 60vh;
    right: -120px;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;

}

#side-tab p{
    font-family: "railroad-gothic-atf", sans-serif;
    font-weight: 400;
    color: #FBFCEA;
    text-transform: uppercase;
    font-size: 32px;
}

@media only screen and (max-width: 750px) {
    #side-tab{
        background-color: #F47FDF;
        position: fixed;
        width: 250px;
        height: 40px;
        top: 60vh;
        right: -105px;
        transform: rotate(-90deg);
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
    
    #side-tab p{
        font-family: "railroad-gothic-atf", sans-serif;
        font-weight: 400;
        color: #FBFCEA;
        text-transform: uppercase;
        font-size: 24px;
    }
  }