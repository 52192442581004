#keyboard{
    background-image: url('../Assets/keys.jpg');
    width: 100vw;
    height: 600px;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#keyboard h2{
    text-transform: uppercase;
    font-size: 64px;
    color: #FBFCEA;
    width: 1000px;
    max-width: 90vw;
}

@media only screen and (max-width: 550px) {
    #keyboard h2{
        font-size: 14vw;
    }
}