#already-work-with-us{
    background-image: url('../Assets/sax.webp');
    width: 100vw;
    background-position: center;
    background-size: cover;
}

#interested{
    background: linear-gradient(130deg, rgba(27,27,27,1) 0%, rgba(37,37,37,1) 100%);
    padding-top: 100px;
}

#interested-left{
    width: 50%;
    color: #F47FDF;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

#interested-right{
    padding: 0;
    width: 45%;
}

#interested-left ul{
    margin: 30px 0;
    padding-inline-start: 50px;
}

#interested-left h2{
    color: #FBFCEA;
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 30px;
}

#work-with-us-container, #interested-container{
    width: 1400px;
    margin: 0 auto;
    padding: 120px 30px;
    max-width: 100vw;
}

#interested-container{
    display: flex;
    justify-content: space-between;
}

#work-with-us-container h2{
    color: #F47FDF;
    font-size: 64px;
}

#work-with-us-container p{
    color: #FBFCEA;
    font-size: 24px;
    font-weight: 600;
    width: 550px;
    max-width: 100%;
}

#app-icon-container{
    display: flex;
    margin: 50px 0;
}

.app-store-icon{
    height: 60px;
    margin-right: 30px;
}

@media only screen and (max-width: 1200px) {
    #interested-container{
        flex-direction: column;
        justify-content: center;
        width: 800px;
        max-width: 100%;
        padding: 0 30px 30px 30px;
    }

    #interested-left, #interested-right{
        width: 100%;
        margin: 1rem 0;
    }

    #interested-left h2, #interested-right{
        text-align: center;
    }

   
  }

  #interested-left li{
    margin: 10px 0
}

  @media only screen and (max-width: 750px) {
    #work-with-us-container{
        text-align: center;
    }
    #app-icon-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 50px 0;
    }
    #work-with-us-container p{
        margin: 0 auto
    }

    .app-store-icon{
        height: auto;
        margin-right: 0;
        width: 100%;
        max-width: 250px;
    }

    .app-link{
        width: 40%;
    }
  }

  @media only screen and (max-width: 550px) {
    .app-link{
        width: 70%;
    }

    .app-store-icon{
        margin: 10px 0;
    }

    #work-with-us-container h2, #interested-left h2{
        font-size: 48px;
        line-height: 56px;

    }

    #interested-left{
        
        font-size: 20px;
        line-height: 24px;
    }

    #interested-left ul{
        padding-inline-start: 30px;
    }
  }