#preview{
    position: relative;
}

.play-btn{
    position: absolute;
    z-index: 999;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    width: 100px;
    height: 100px;

}

.mute-btn{
    position: absolute;
    z-index: 999;
    right: 3vw;
    bottom: 3vw;
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 600px) {
    .play-btn{
        position: absolute;
        z-index: 999;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        width: 50px;
        height: 50px;
    
    }

    .mute-btn{
        
        width: 25px;
        height: 25px;
    }
  }